.privacy-wrapper {
    display: flex;
    flex-direction: column;
}

.privacy-text {
    color: white;
    font-weight: 300;
    max-width: 60%;
    margin-bottom: 120px;
}
