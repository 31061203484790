.c-operator-wrapper {
    margin-top: 40px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: baseline;
}

.c-operator-select {
    width: 50%;
    margin-left: 0;
    min-width: 240px;
    font-weight: 600;
    border-radius: 20px;
    border: 2px solid #8DCBAD;
}

.c-duration-select {
    width: 50%;
    margin-left: 0;
    margin-top: 20px;
    min-width: 240px;
    font-weight: 600;
    border-radius: 20px;
    border: 2px solid #8DCBAD;
}

.c-operator-dropdown {
    font-family: $font-face, sans-serif;
    font-weight: $font-weight-regular;
    border-radius: 10px;
    border: 2px #222222;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.c-operator-list {
    display: flex;
    max-width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.c-operator-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px;
    padding: 10px;
    border-radius: 20px;
    border: 2px solid #8DCBAD;
}

.operator-graph-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.operator-graph {
    margin-top: 50px;
    width: 400px;
    height: 400px;
}

.other-graph {
    margin-top: 50px;
    width: 400px;
    height: 200px;
}

.mode-graph {
    margin-top: 50px;
    margin-bottom: 300px;
    width: 400px;
    height: 200px;
}

.chart-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
