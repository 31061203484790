.u-circle {
    border-radius: 50%;
}

.u-push-right {
    margin-right: auto;
}

.u-clear-margin-padding {
    padding: 0;
    margin: 0;
}

.u-clear-link:any-link {
    text-decoration: none;
    color: inherit;
}

.u-small-right-margin {
    margin-right: 10px;
}

.u-tiny-right-margin {
    margin-right: 5px;
}

.u-small-border {
    border: 2px solid white;
}

.u-login-background {
    // background: url("../../assets/images/login-background.png") no-repeat;
    background-color: #74C302;
    background-size: cover;
}

.u-vertical-margin-small {
    margin: 10px 0 10px 0;
}

.u-vertical-margin-big {
    margin: 30px 0 30px 0;
}

.u-no-border {
    border: none;
}
