.logo {
    margin-top: 15px;
    height: 120px;
    width: 120px;
}

.policy-title {
    font-size: 38px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
    color: white;
}

.privacy-heading {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    max-width: 60%;
    color: white;
    margin-bottom: 15px;
}

.privacy-heading-2 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    max-width: 60%;
    color: white;
    margin-bottom: 15px;
}

.privacy-description {
    font-size: 16px;
    text-align: justify;
    max-width: 70%;
    margin-bottom: 50px;
    color: white;
}

hr.divider {
    border-top: 2px solid white;
    border-radius: 1px;
    width: 80%;
}

.footer-wrapper {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-bottom: 20px;
}

.footer-column {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    text-align: left;
    color: white;
}
