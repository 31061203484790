$font-face-arlrdbd: Arlrdbd;
$font-weight-light-arlrbd: 300;

@font-face {
    font-family: $font-face-arlrdbd;
    src: url('../../assets/fonts/arlrdbd.ttf') format('ttf'),
    url('../../assets/fonts/arlrdbd.ttf') format('ttf');
    font-weight: $font-weight-light-arlrbd;
    font-style: normal;
}
