.c-home-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.c-home-header {
    display: flex;
    font-size: 10px;
    color: #76AE45;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.c-home-language {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.c-home-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    &__background {
        position: absolute;
    }

    &__main {
        margin-left: 80px;
        display: flex;
        margin-top: 20px;
        margin-bottom: 30px;
        flex-direction: row;
        align-items: center;

        &__icons {
            display: flex;
            flex-direction: column;
        }

        &__titles {
            display: flex;
            flex-direction: column;
            align-content: center;

            &__element {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 40px;

                &__image {
                    max-height: 30px;
                    color: #67A530;
                }

                &__text_wrap {
                    display: flex;
                    flex-direction: column;

                    &__text {
                        margin-left: 20px;
                        margin-top: 15px;
                        line-height: initial;
                        font-size: 12px;
                        font-weight: bold;
                        white-space: pre-line;
                    }

                    &__subtext {
                        margin-left: 20px;
                        line-height: initial;
                        color: #666566;
                        font-size: 8px;
                        font-weight: bold;
                        white-space: pre-line;
                    }
                }
            }
        }
    }
}


.u-home-button-wrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 23%;
    bottom: -17%;
}

.c-home-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 80px;
    align-items: center;
}
