$font-face-opensans-extrabold: OpensansExtrabold;
$font-weight-light-opensans-extrabold: 300;

@font-face {
    font-family: $font-face-opensans-extrabold;
    src: url('../../assets/fonts/opensans-extrabold.ttf') format('ttf'),
    url('../../assets/fonts/opensans-extrabold.ttf') format('ttf');
    font-weight: $font-weight-light-opensans-extrabold;
    font-style: normal;
}
