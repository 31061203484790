.operator-graph-container {
    display: flex;
    flex-direction: column;
    min-width: 95%;
    align-items: center;
}

.operator-graph {
    min-width: 80%;
    height: 250px;
    margin-left: 10px;
}

.operator-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.operator-details-username {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.operator-details-text {
    font-size: 16px;
    margin-bottom: 10px;
}

.operator-details-status {
    font-size: 16px;
    margin-bottom: 10px;
}
