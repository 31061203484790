.u-wrapper {
    display: flex;
    flex-direction: column;
}

.c-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 70%;
    margin-bottom: 10px;
}

.u-image {
    width: 70px;
    height: 70px;
}

.u-span {
    white-space: pre-line;
    text-align: center;
    color: #76AE45;
}

