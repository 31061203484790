.c-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    @extend %navigation-size;

    @media all and (min-height: $app-max-height) {
        position: absolute;
    }

    &__content {
        @extend %app-component;
        background: #76AE45;
        padding: 0 60px 0 60px;
    }
}
