.c-header {
    position: fixed;
    left: 0;
    top: 0;
    @extend %navigation-size;

    @media all and (min-height: $app-max-height) {
        position: absolute;
    }

    &__title {
        @extend %nowrap;
    }

    &__content {
        @include font-size($header-font-size);
        @extend %app-component;
        color: $color-white;
        // background-image: linear-gradient(to right, $color-primary, $color-tertiary);
        background-color: #019235;
        padding: 0 $unit-small;
        margin: 0 auto;
        flex-wrap: nowrap;
    }
}
